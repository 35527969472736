import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Box } from "@chakra-ui/react";
import FloatingButtons from "./src/components/floatingButtons";

const queryClient = new QueryClient();

export const wrapPageElement = ({ element, props }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Box>
        {element}
        {/* <FloatingButtons {...props}/> */}
      </Box>
    </QueryClientProvider>
  );
};

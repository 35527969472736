const Button = {
  baseStyle: {
    fontWeight: "700",
    boxShadow: 'none',
    fontFamily: 'Mardoto',
    borderRadius: '10px',
    fontSize: '13px',
    _focus: {
      boxShadow: "none",
      outline: 'none',
    },
    _disabled: {
      cursor: 'pointer !important'
    },
  },
  variants: {
    green: {
      backgroundColor: 'green.100',
      color: 'white',
      _hover: {
        backgroundColor: 'rgba(58, 168, 53, 0.7)',
        _disabled: {
            backgroundColor: 'green.100',
        },
      },
      _active: {
        backgroundColor: 'rgba(58, 168, 53, 0.7)'
      }
    },
    darkGreen: {
      backgroundColor: 'green.200',
      color: 'white',
      _hover: {
        backgroundColor: 'rgba(0, 108, 73, 0.7)',
        _disabled: {
            backgroundColor: 'green.200',
        },
      },
      _active: {
        backgroundColor: 'rgba(0, 108, 73, 0.7)'
      }
    },
    transparent: {
      backgroundColor: 'transparent',
      color: 'black.100',
      border: "1px solid",
      borderColor: "rgba(32, 32, 32, 0.15)",
      boxShadow: 'none',
      _hover: {
          opacity: 0.75,
      }
    },
    grey: {
      backgroundColor: 'grey.200',
      color: 'black.100',
      borderRadius: "6px",
      height: '60px',
      width: '60px',
      _hover: {
        backgroundColor: 'rgba(234, 234, 234, 0.7)',
        _disabled: {
            backgroundColor: 'grey.200',
        },
      },
      _active: {
        backgroundColor: 'rgba(234, 234, 234, 0.7)'
      }
    },
    text: {
      border: 0,
      backgroundColor: 'transparent',
      color: 'black.100',
      padding: 0,
      height: 'unset',
      minHeight: 'unset',
      fontSize: '14px',
      _hover: {
          opacity: 0.75,
      }
    }
  },
  sizes: {
    sm: {

    },
    md: {
        height: '42px',
        fontSize: "13px",
    },
    lg: {

    }
  },
  // The default size and variant values
  defaultProps: {
      size: "md",
      variant: "text",
  },
}

export default Button

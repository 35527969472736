exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-all-financial-education-events-index-jsx": () => import("./../../../src/pages/all-financial-education-events/index.jsx" /* webpackChunkName: "component---src-pages-all-financial-education-events-index-jsx" */),
  "component---src-pages-all-information-booklets-aboutus-index-jsx": () => import("./../../../src/pages/all-information-booklets-aboutus/index.jsx" /* webpackChunkName: "component---src-pages-all-information-booklets-aboutus-index-jsx" */),
  "component---src-pages-all-information-booklets-index-jsx": () => import("./../../../src/pages/all-information-booklets/index.jsx" /* webpackChunkName: "component---src-pages-all-information-booklets-index-jsx" */),
  "component---src-pages-all-reports-index-jsx": () => import("./../../../src/pages/all-reports/index.jsx" /* webpackChunkName: "component---src-pages-all-reports-index-jsx" */),
  "component---src-pages-announcements-index-jsx": () => import("./../../../src/pages/announcements/index.jsx" /* webpackChunkName: "component---src-pages-announcements-index-jsx" */),
  "component---src-pages-central-depositary-index-jsx": () => import("./../../../src/pages/central-depositary/index.jsx" /* webpackChunkName: "component---src-pages-central-depositary-index-jsx" */),
  "component---src-pages-conference-index-jsx": () => import("./../../../src/pages/conference/index.jsx" /* webpackChunkName: "component---src-pages-conference-index-jsx" */),
  "component---src-pages-conferences-index-jsx": () => import("./../../../src/pages/conferences/index.jsx" /* webpackChunkName: "component---src-pages-conferences-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-credit-history-index-jsx": () => import("./../../../src/pages/credit-history/index.jsx" /* webpackChunkName: "component---src-pages-credit-history-index-jsx" */),
  "component---src-pages-currency-exchange-index-jsx": () => import("./../../../src/pages/currency-exchange/index.jsx" /* webpackChunkName: "component---src-pages-currency-exchange-index-jsx" */),
  "component---src-pages-deposit-bank-account-index-jsx": () => import("./../../../src/pages/deposit-bank-account/index.jsx" /* webpackChunkName: "component---src-pages-deposit-bank-account-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-fill-complaint-online-index-jsx": () => import("./../../../src/pages/fill-complaint-online/index.jsx" /* webpackChunkName: "component---src-pages-fill-complaint-online-index-jsx" */),
  "component---src-pages-finance-organization-index-jsx": () => import("./../../../src/pages/finance-organization/index.jsx" /* webpackChunkName: "component---src-pages-finance-organization-index-jsx" */),
  "component---src-pages-financial-education-events-index-jsx": () => import("./../../../src/pages/financial-education-events/index.jsx" /* webpackChunkName: "component---src-pages-financial-education-events-index-jsx" */),
  "component---src-pages-financial-education-index-jsx": () => import("./../../../src/pages/financial-education/index.jsx" /* webpackChunkName: "component---src-pages-financial-education-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-information-videos-index-jsx": () => import("./../../../src/pages/information-videos/index.jsx" /* webpackChunkName: "component---src-pages-information-videos-index-jsx" */),
  "component---src-pages-insurance-index-jsx": () => import("./../../../src/pages/insurance/index.jsx" /* webpackChunkName: "component---src-pages-insurance-index-jsx" */),
  "component---src-pages-investment-company-and-fund-index-jsx": () => import("./../../../src/pages/investment-company-and-fund/index.jsx" /* webpackChunkName: "component---src-pages-investment-company-and-fund-index-jsx" */),
  "component---src-pages-loan-index-jsx": () => import("./../../../src/pages/loan/index.jsx" /* webpackChunkName: "component---src-pages-loan-index-jsx" */),
  "component---src-pages-mediators-biography-index-jsx": () => import("./../../../src/pages/mediators-biography/index.jsx" /* webpackChunkName: "component---src-pages-mediators-biography-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-reports-index-jsx": () => import("./../../../src/pages/news-reports/index.jsx" /* webpackChunkName: "component---src-pages-news-reports-index-jsx" */),
  "component---src-pages-online-survey-index-jsx": () => import("./../../../src/pages/online-survey/index.jsx" /* webpackChunkName: "component---src-pages-online-survey-index-jsx" */),
  "component---src-pages-payment-card-index-jsx": () => import("./../../../src/pages/payment-card/index.jsx" /* webpackChunkName: "component---src-pages-payment-card-index-jsx" */),
  "component---src-pages-pledge-index-jsx": () => import("./../../../src/pages/pledge/index.jsx" /* webpackChunkName: "component---src-pages-pledge-index-jsx" */),
  "component---src-pages-quick-questions-index-jsx": () => import("./../../../src/pages/quick-questions/index.jsx" /* webpackChunkName: "component---src-pages-quick-questions-index-jsx" */),
  "component---src-pages-recent-publications-index-jsx": () => import("./../../../src/pages/recent-publications/index.jsx" /* webpackChunkName: "component---src-pages-recent-publications-index-jsx" */),
  "component---src-pages-search-index-jsx": () => import("./../../../src/pages/search/index.jsx" /* webpackChunkName: "component---src-pages-search-index-jsx" */),
  "component---src-pages-sign-up-index-jsx": () => import("./../../../src/pages/sign-up/index.jsx" /* webpackChunkName: "component---src-pages-sign-up-index-jsx" */),
  "component---src-pages-single-news-index-jsx": () => import("./../../../src/pages/single-news/index.jsx" /* webpackChunkName: "component---src-pages-single-news-index-jsx" */),
  "component---src-pages-standart-document-examples-index-jsx": () => import("./../../../src/pages/standart-document-examples/index.jsx" /* webpackChunkName: "component---src-pages-standart-document-examples-index-jsx" */),
  "component---src-pages-submit-claim-application-index-jsx": () => import("./../../../src/pages/submit-claim-application/index.jsx" /* webpackChunkName: "component---src-pages-submit-claim-application-index-jsx" */),
  "component---src-pages-submit-claim-index-jsx": () => import("./../../../src/pages/submit-claim/index.jsx" /* webpackChunkName: "component---src-pages-submit-claim-index-jsx" */),
  "component---src-pages-transfer-index-jsx": () => import("./../../../src/pages/transfer/index.jsx" /* webpackChunkName: "component---src-pages-transfer-index-jsx" */),
  "component---src-pages-trusteesborads-biography-index-jsx": () => import("./../../../src/pages/trusteesborads-biography/index.jsx" /* webpackChunkName: "component---src-pages-trusteesborads-biography-index-jsx" */),
  "component---src-pages-useful-information-index-jsx": () => import("./../../../src/pages/useful-information/index.jsx" /* webpackChunkName: "component---src-pages-useful-information-index-jsx" */),
  "component---src-pages-view-complaint-claim-index-jsx": () => import("./../../../src/pages/view-complaint-claim/index.jsx" /* webpackChunkName: "component---src-pages-view-complaint-claim-index-jsx" */),
  "component---src-pages-what-to-expect-index-jsx": () => import("./../../../src/pages/what-to-expect/index.jsx" /* webpackChunkName: "component---src-pages-what-to-expect-index-jsx" */),
  "component---src-pages-your-opinion-index-jsx": () => import("./../../../src/pages/your-opinion/index.jsx" /* webpackChunkName: "component---src-pages-your-opinion-index-jsx" */)
}


import { extendTheme } from "@chakra-ui/react";
import Button from "./Button";
import "../../css/typography.css";

const theme = {
  components: {
    Button,
  },
  fonts: {
    body: "Mardoto , serif",
    heading: "Mardoto , serif",
    mono: "Mardoto , serif",
  },
  styles: {
    global: {
      "html, body": {
        color: "black.100",
        background: "white",
        fontWeight: 400,
      },
      body: {
        letterSpacing: '1px'
      }
    },
  },
  colors: {
    black: {
      100: "#202020",
      200: "#000000"
    },
    green: {
      100: "#3AA835",
      200: "#006C49",
      500: "#3AA835",
    },
    grey: {
      100: "#6A6A6A",
      200: "#EAEAEA",
      300: "#c2c2c2",
    },
    border: {
      100: "#DDDDDD",
    },
  },
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
    "6xl": "3.75rem", // 60px
    "7xl": "4.5rem", // 72px
    "8xl": "6rem", // 96px
    "9xl": "8rem", // 128px
  },
};

export default extendTheme(theme);
